// import logo from './logo.svg';
// import './App.css';
// import LandingPage from './LandingPage';
// import { BrowserRouter, Route, Routes } from "react-router-dom";

// import Waiting from "./WaitingPage/Waiting";
import Table from "./ADMIN/Table";

function App() {
  return (
    <div>
      <Table/>
  {/* <LandingPage/> */}
   {/* <BrowserRouter>
        <Routes>
     <Route path='/' element={<Waiting/>}/>
         
</Routes>
      </BrowserRouter> */}
          {/* <Route  path="admin" element={<Table/>}/> */}
    </div>
  );
}

export default App;
